"use client";

import { FC } from "react";
import styles from "./SiteHeader.module.css";
import Link from "next/link";
import Image from "next/image";
import incAnastasiaLogo from "@/app/inc_anastasia_logo.webp";
import anastasiaLogo from "@/app/anastasia_logo.webp";
import { usePathname } from "next/navigation";

export const SiteHeader: FC = function () {
  const pathname = usePathname();

  return (
    <header className={styles.header}>
      <a href="https://www.musicalinc.de" className={styles.inc}>
        <Image src={incAnastasiaLogo} alt="Musical Inc." />
      </a>
      <Link
        href={pathname.startsWith("/intern") ? "/intern" : "/"}
        className={styles.logo}
      >
        <Image src={anastasiaLogo} alt="Anastasia" />
      </Link>
    </header>
  );
};
